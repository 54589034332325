import React from 'react'

const Change = () => {
    return (
        <>
            <div className='portfolio-container'>
                <h1 className='my-projects-title'>Coming Soon!</h1>
            </div>

        </>
    )
}

export default Change